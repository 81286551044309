<template>
  <div>
    <div
      class="col-12 border"
    >
      <b-row
        cols="12"
        class="mb-1 mt-1"
      > <!-- filtre satırı -->
        <b-col
          cols="6"
          md="3"
        >
          <b-form-radio
            v-model="deger"
            name="some-radios3"
            :value="0"
            plain
          >
            <strong> Eşleştirilmemiş Aktif Talepler</strong>
          </b-form-radio>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-radio
            v-model="deger"
            plain
            name="some-radios3"
            :value="1"
          >
            <strong>Eşleştirilmiş Aktif Talepler</strong>
          </b-form-radio>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-radio
            v-model="deger"
            plain
            name="some-radios3"
            :value="-1"
          >
            <strong>Pasif Talepler</strong>
          </b-form-radio>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-radio
            v-model="deger"
            plain
            name="some-radios3"
            :value="2"
          >
            <strong> Kapatılmış Talepler</strong>
          </b-form-radio>
        </b-col>
      </b-row>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="requests"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder:'Talep Ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> Talep listesi kayıt bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-10"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-warning"
                  @click="onUpdateReqBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-if="deger===1 || deger===0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-dark"
                  @click="onPasiveReqBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="PauseIcon"
                    class="mr-50"
                  />
                  <span>Pasifleştir</span>
                </b-button>
                <b-button
                  v-if="deger===-1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-success"
                  @click="onActiveReqBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="PlayIcon"
                    class="mr-50"
                  />
                  <span>Aktifleştir</span>
                </b-button>
                <b-button
                  v-if="deger!==2"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-danger"
                  @click="onCloseReqBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Kapat</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              1 ila
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import * as apiTalep from '@/api/islemler/firmRequest'
import {
  BPagination, BFormSelect, BButtonGroup, BButton, VBModal, BFormRadio, BCol, BRow, // BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { mapActions } from 'vuex'
/*
     * 0 Aktif Eşleştirilmemiş
     * 1 Aktif Eşleştirilmiş
     * -1 Pasif
     * 2 Kapatılmış
    */
export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButtonGroup,
    BButton,
    // BFormGroup,
    BFormRadio,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      newFirmId: 0,
      newFirmTitle: '',
      upFirmTitle: '',
      pageLength: 50,
      deger: 0,
      req: {},
      requests: [
        {
          label: 'Firma Ünvanı',
          field: 'firmInfo.firmTitle',
        },
        {
          label: 'Pozisyon',
          field: 'position',
        },
        {
          label: 'Durum',
          field: 'jobType',
        },
        {
          label: 'Sayı',
          field: 'personelCount',
        },
        {
          label: 'Talep Tarihi',
          field: 'requestOpenDate',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  watch: {
    deger() {
      this.GetSummary(this.deger)
    },
  },
  created() {
    this.GetSummary(0)
  },
  methods: {
    onDataGridItemClicked(params) {
      return params
    },
    GetSummary(deger) {
      apiTalep.GetSummary(deger).then(res => {
        this.rows = res.data.list
      })
    },
    onUpdateReqBtnClicked(row) {
      this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestId, firmInfo: row.firmInfo } })
    },
    onPasiveReqBtnClicked(row) {
      apiTalep.GetStaffRequestById(row.staffRequestId).then(rqst => {
        this.req = rqst.data.entity
        this.req.requestStatus = -1
        apiTalep.UpdateStaffRequest(this.req).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Talep Durum',
              text: `${row.firmInfo.firmTitle} firmasının ${row.position} talebi pasifleştirilmiştir..`,
              icon: 'PauseIcon',
              variant: 'dark',
            },
          })
          this.GetSummary(this.deger)
        })
      })
    },
    onActiveReqBtnClicked(row) {
      apiTalep.GetStaffRequestById(row.staffRequestId).then(rqst => {
        this.req = rqst.data.entity
        this.req.requestStatus = 0
        apiTalep.UpdateStaffRequest(this.req).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Talep Durum',
              text: `${row.firmInfo.firmTitle} firmasının ${row.position} talebi yeniden aktifleştirilmiştir..`,
              icon: 'PlayIcon',
              variant: 'success',
            },
          })
          this.GetSummary(this.deger)
        })
      })
    },
    onCloseReqBtnClicked(row) {
      const h = this.$createElement
      const messageVNode = h('div', [
        h('p', [`${row.firmInfo.firmTitle} firmasının ${row.position} talebi için bir daha işlem yapamayacaksınız!!`]),
        h('p', { class: ['text-center font-weight-bold text-danger'] }, ['Emin misiniz?']),
      ])
      this.$bvModal
        .msgBoxConfirm(messageVNode, {
          title: 'Talep Kapatma Onayı',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Evet',
          cancelTitle: 'Hayır',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            apiTalep.GetStaffRequestById(row.staffRequestId).then(rqst => {
              this.req = rqst.data.entity
              this.req.requestStatus = 2
              apiTalep.UpdateStaffRequest(this.req).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Talep Durum',
                    text: `${row.firmInfo.firmTitle} firmasının ${row.position} talebi kapatılmıştır..`,
                    icon: 'PlayIcon',
                    variant: 'success',
                  },
                })
                this.GetSummary(this.deger)
              })
            })
          }
        })
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
